import React, { useState, useRef, useEffect } from 'react'
import { PropTypes } from 'prop-types'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import {
  Container,
  Button,
  Grid,
  InputAdornment,
  IconButton,
  FormHelperText,
  Link,
  Paper,
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Hidden,
} from '@material-ui/core'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { useForm } from 'react-hook-form'
import clsx from 'clsx'
import _ from 'lodash'
import { v4 as uuidv4 } from 'uuid'
import CreateOutlinedIcon from '@material-ui/icons/CreateOutlined'
import CreditCardOutlinedIcon from '@material-ui/icons/CreditCardOutlined'
import Card from 'react-credit-cards'
import CardPayment from 'payment'
// import { Auth } from 'aws-amplify'
import { useSnackbar } from 'notistack'
import Title from '../../components/common/text/Title'
import { useTrademarkContext, useLayoutContext } from '../../components/context'
import {
  FormField,
  PhoneField,
  FormFieldMultipleOptions,
  mapIcon,
  NumberFormatCustom,
  cardExpiryFormat,
  CardMasterIcon,
  CardVisaIcon,
  CardAmexIcon,
  currencyFormat,
} from '../../components'
import { isEmail } from '../../regex.validations'
import { useInfoModal } from '../../hooks'
import {
  TAX_RATE,
  paymentMethods,
  PAYMENT_METHOD_CARD,
  PAYMENT_METHOD_DEPOSIT,
  PAYMENT_METHOD_STORE,
  acceptedCards,
  // CARD_ISSUER_VISA,
  // CARD_ISSUER_MASTERCARD,
  CARD_ISSUER_AMEX,
  OPENPAY_MERCHANT_ID,
  OPENPAY_PUBLIC_API_KEY,
  OPENPAY_IS_SANBOX,
  PAYMENT_TX_STATUS_PAID,
  FEE_TYPE_TRADEMARK_REGISTRATION,
  FEE_TYPE_IMPI_RIGHTS,
  trademarkApplicationStatuses,
  trademarkApplicationStages,
  whatsappContactMessages,
  emailContactMessages,
  WHATSAPP_PHONE,
  MAILTO_ADDRESS,
  voucherTypes,
} from '../../config'
import {
  uploadFilesToS3,
  postTrademarkPreApplication,
  postTrademarkApplicationProductsServices,
  createCardPayment,
  createStoreOrDepositPayment,
  readPaymentTransactionStatus,
  feeByTypeAndStatus,
  readTrademarkApplication,
  moveTrademarkToStage,
  getPaymentVoucher,
} from '../../services'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
  },
  nextButtons: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  nextButtonContainer: {
    justifyContent: 'flex-end',
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'center',
    },
    alignItems: 'center',
  },
  nextButton: {
    padding: theme.spacing(2),
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(1.5),
    },
  },
  noLabel: {
    marginTop: theme.spacing(1),
  },
  formHelperText: {
    marginLeft: theme.spacing(1.75),
    marginRight: theme.spacing(1.75),
    cursor: 'pointer',
  },
  infoText: {
    fontSize: 12,
    fontWeight: 'bold',
    color: theme.palette.secondary.main,
    textTransform: 'none',
    justifyContent: 'center',
    textAlign: 'center',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  tableTitle: {
    flex: '1 1 100%',
    fontSize: '1rem',
    fontWeight: 700,
    textTransform: 'uppercase',
    color: theme.palette.secondary.main,
    padding: theme.spacing(1),
  },
  tableHeaders: {
    fontSize: '0.9rem',
    [theme.breakpoints.up('sm')]: {
      fontSize: '0.95 rem',
    },
    fontWeight: 600,
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(1.2),
    },
  },
  tableText: {
    fontSize: '0.8rem',
    [theme.breakpoints.up('sm')]: {
      fontSize: '0.85rem',
    },
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(1.2),
    },
  },
  emphasizedText: {
    fontSize: '0.8rem',
    [theme.breakpoints.up('sm')]: {
      fontSize: '0.85rem',
    },
    fontWeight: 600,
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(1.2),
    },
  },
  invoiceTotal: {
    fontSize: '0.85rem',
    [theme.breakpoints.up('sm')]: {
      fontSize: '0.9rem',
    },
    fontWeight: 800,
  },
  noBorder: {
    border: 0,
  },
  paymentMethod: {
    marginTop: theme.spacing(3),
  },
  paymentMethods: {
    textAlign: 'left',
  },
  paymentMethodIcon: {
    fontSize: theme.typography.fontSize,
    marginRight: theme.typography.fontSize,
  },
  card: {
    '& > div > div > div, & > div > div > div > div': {
      [theme.breakpoints.down('xs')]: {
        width: 279,
        height: 175,
      },
    },
  },
  cardIssuer: {
    maxHeight: theme.typography.fontSize,
  },
  openpayLogoContainer: {
    verticalAlign: 'middle',
    textAlign: 'right',
  },
  openpayLogo: {
    border: 'none',
    padding: '0.25rem',
    borderRadius: '0.25rem',
    maxHeight: theme.spacing(4),
  },
  referenceButton: {
    textAlign: 'right',
    [theme.breakpoints.down('xs')]: {
      textAlign: 'center',
    },
  },
  // https://www.smashingmagazine.com/2014/02/making-embedded-content-work-in-responsive-design/
  // https://benmarshall.me/responsive-iframes/
  // https://stackoverflow.com/questions/17838607/making-an-iframe-responsive
  referencePDFContainer: {
    overflow: 'hidden',
    // aspect ratio
    paddingBottom: '75% !important',
    [theme.breakpoints.down('lg')]: {
      paddingBottom: '75% !important',
    },
    [theme.breakpoints.down('md')]: {
      paddingBottom: '100% !important',
    },
    [theme.breakpoints.down('sm')]: {
      paddingBottom: '120% !important',
    },
    [theme.breakpoints.down('xs')]: {
      paddingBottom: '140% !important',
    },
    position: 'relative',
    '& > iframe': {
      border: 0,
      height: '100%',
      left: 0,
      position: 'absolute',
      top: 0,
      width: '100%',
    },
  },
}))

const Payment = () => {
  const classes = useStyles()
  const theme = useTheme()
  const {
    setCurrentStep,
    brand,
    setBrand,
    preApplicationID,
    setPreApplicationID,
    filesUploaded,
    setFilesUploaded,
    paymentTransactionID,
    setPaymentTransactionID,
    trademarkAppID,
    setTrademarkAppID,
    productsServicesCreated,
    setProductsServicesCreated,
    generatedPayment,
    setGeneratedPayment,
    setTrademarkApp,
    openCancelTradeMarkRegistrationModal,
  } = useTrademarkContext()
  const {
    setLoading,
    orientation,
    windowSize,
    authenticated,
    currentUser,
    setWhatsappContactStatus,
    setEmailContactStatus,
    setContactSpeedDialEnabled,
  } = useLayoutContext()
  const { enqueueSnackbar } = useSnackbar()
  const {
    register,
    handleSubmit,
    control,
    errors,
    getValues,
    trigger,
    watch,
    setValue,
  } = useForm({
    defaultValues: {
      ...brand?.generalData,
      paymentMethod: brand?.generalData?.paymentMethod,
    },
  })
  const [enabledEmail, setEnabledEmail] = useState(false)
  const { openModal } = useInfoModal()
  const emailRef = useRef()
  const paymentMethod = watch('paymentMethod')
  const currentFieldValues = watch([
    'paymentMethod',
    'email',
    'emailConfirmation',
    'phoneNumber',
    'phoneNumberDialCode',
  ])
  const cardNumber = watch('cardNumber')
  const cardholderName = watch('cardholderName')
  const cardExpirationDate = watch('cardExpirationDate')
  const cardCVCCode = watch('cardCVCCode')
  const [cardFocused, setCardFocused] = useState('')
  // eslint-disable-next-line no-unused-vars
  const [validCard, setValidCard] = useState(false)
  const [cardIssuer, setCardIssuer] = useState(null)
  // eslint-disable-next-line no-unused-vars
  const [referenceUrl, setReferenceUrl] = useState(null)
  const [referenceKey, setReferenceKey] = useState(null)
  const [trademarkRegistrationFee, setTrademarkRegistrationFee] = useState(null)
  const [impiRightsFee, setImpiRightsFee] = useState(null)

  useEffect(() => {
    setContactSpeedDialEnabled(true)
    const whatsapp = whatsappContactMessages.unauthenticatedUserPayment(
      brand?.generalData?.brandName,
      brand?.generalData?.email
    )

    const email = emailContactMessages.authenticatedUserPayment(
      trademarkAppID,
      brand?.generalData?.brandName,
      brand?.generalData?.email
    )

    setWhatsappContactStatus({
      number: WHATSAPP_PHONE,
      text: whatsapp,
    })

    setEmailContactStatus({
      email: MAILTO_ADDRESS,
      subject: email?.subject,
      body: email?.message,
    })
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [
    whatsappContactMessages,
    emailContactMessages,
    setWhatsappContactStatus,
    setEmailContactStatus,
  ])

  useEffect(() => {
    setReferenceKey(uuidv4())
  }, [orientation, windowSize])

  const retrieveFees = async () => {
    setLoading(true)
    const registrationFee = await feeByTypeAndStatus({
      type: FEE_TYPE_TRADEMARK_REGISTRATION,
      authenticated,
    })
    const rightsFee = await feeByTypeAndStatus({
      type: FEE_TYPE_IMPI_RIGHTS,
      authenticated,
    })
    setTrademarkRegistrationFee(registrationFee)
    setImpiRightsFee(rightsFee)
    setLoading(false)
  }

  useEffect(() => {
    retrieveFees()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authenticated])

  useEffect(() => {
    if (currentUser) {
      setValue('email', currentUser.username)
      setValue('emailConfirmation', currentUser.username)
    }
  }, [currentUser, setValue])

  const paymentMethodsOptions = paymentMethods.map((option) => {
    const { Icon } = mapIcon(option)
    return {
      value: option.value,
      label: option.label,
      icon: <Icon className={classes.paymentMethodIcon} />,
    }
  })

  const createTrademarkApplicationProductsServices = async (
    productsAndServices,
    createdPreApplicationID,
    owner
  ) => {
    if (!productsAndServices || productsAndServices.length === 0) return []
    const createdProductsAndServices = productsAndServices.map(
      async (productAndService) => {
        const createdProductAndService = await postTrademarkApplicationProductsServices(
          {
            productServiceID: productAndService.id,
            preApplicationID: createdPreApplicationID,
            owner: authenticated ? undefined : owner,
            authenticated,
          }
        )
        return createdProductAndService
      }
    )
    const all = await Promise.all(createdProductsAndServices)
    return all
  }

  const configureOpenPay = () => {
    // eslint-disable-next-line no-undef
    OpenPay.setId(OPENPAY_MERCHANT_ID)
    // eslint-disable-next-line no-undef
    OpenPay.setApiKey(OPENPAY_PUBLIC_API_KEY)
    if (OPENPAY_IS_SANBOX === 'true') {
      // eslint-disable-next-line no-undef
      OpenPay.setSandboxMode(true)
    }
  }

  const validateCard = ({ cn, ced, cvv }) => {
    let valid = true
    // eslint-disable-next-line no-undef
    if (!OpenPay.card.validateCardNumber(cn)) {
      valid = false
      setLoading(false)
      enqueueSnackbar('El número de tarjeta no es válido', {
        preventDuplicate: true,
        variant: 'error',
      })
    }
    // eslint-disable-next-line no-undef
    if (valid && !OpenPay.card.validateCVC(cvv, cn)) {
      valid = false
      setLoading(false)
      enqueueSnackbar('El código de verificación no es válido', {
        preventDuplicate: true,
        variant: 'error',
      })
    }
    const expirationMonth = ced.substring(0, 2)
    const expirationYear = ced.substring(2, 4)
    if (
      valid &&
      // eslint-disable-next-line no-undef
      !OpenPay.card.validateExpiry(expirationMonth, expirationYear)
    ) {
      valid = false
      setLoading(false)
      enqueueSnackbar('La fecha de expiración no es válida', {
        preventDuplicate: true,
        variant: 'error',
      })
    }
    return valid
  }

  const createOpenPayCard = async ({ cn, chn, ced, cvv }) => {
    return new Promise((resolve, reject) => {
      const expirationMonth = ced.substring(0, 2)
      const expirationYear = ced.substring(2, 4)
      const cardData = {
        card_number: cn,
        holder_name: chn,
        expiration_year: expirationYear,
        expiration_month: expirationMonth,
        cvv2: cvv,
        // address: {
        //   city: ' ',
        //   postal_code: ' ',
        //   state: ' ',
        //   line1: ' ',
        //   line2: ' ',
        //   line3: ' ',
        //   country_code: ' ',
        // },
      }
      try {
        // eslint-disable-next-line no-undef
        OpenPay.token.create(
          cardData,
          (response) => {
            resolve(response)
          },
          (e) => {
            reject(e)
          }
        )
      } catch (err) {
        reject(err)
      }
    })
  }

  const retrievePaymentTXStatusWithDelay = async ({ id, delayInms }) => {
    return new Promise((resolve) => {
      setTimeout(async () => {
        resolve(await readPaymentTransactionStatus({ id }))
      }, delayInms)
    })
  }

  const retrievePaymentTXStatus = async ({
    id,
    maxAttempts = 3,
    delayInms = 2000,
  }) => {
    let paymentStatusResponse = null
    let retrieveStatus = true
    for (
      let attempt = 0;
      attempt < maxAttempts && retrieveStatus;
      attempt += 1
    ) {
      // eslint-disable-next-line no-await-in-loop
      paymentStatusResponse = await retrievePaymentTXStatusWithDelay({
        id,
        delayInms,
      })
      if (
        paymentStatusResponse?.status &&
        paymentStatusResponse?.status === PAYMENT_TX_STATUS_PAID
      ) {
        retrieveStatus = false
      }
    }
    return paymentStatusResponse
  }

  const handleRetrieveVoucher = async (method) => {
    setLoading(true)
    const voucher = await getPaymentVoucher({
      id: paymentTransactionID,
      type:
        // eslint-disable-next-line no-nested-ternary
        method === PAYMENT_METHOD_DEPOSIT
          ? voucherTypes.bank
          : method === PAYMENT_METHOD_STORE
          ? voucherTypes.store
          : '',
    })
    setReferenceKey(uuidv4())
    setReferenceUrl(voucher.documentUrl)
  }

  const completeBankOrStorePayment = () => {
    setLoading(false)
    enqueueSnackbar('Ficha de pago generada correctamente', {
      preventDuplicate: true,
      variant: 'success',
    })
  }

  // eslint-disable-next-line no-unused-vars
  const onSubmit = async (data) => {
    setLoading(true)
    const {
      cardNumber: cn,
      cardholderName: chn,
      cardExpirationDate: ced,
      cardCVCCode: cvv,
      ...other
    } = data
    setBrand({ generalData: { ...brand?.generalData, ...other } })

    configureOpenPay()

    let deviceSessionId
    if (paymentMethod === PAYMENT_METHOD_CARD) {
      // eslint-disable-next-line no-undef
      deviceSessionId = OpenPay?.deviceData?.setup('paymentForm', '')
      if (!deviceSessionId) {
        enqueueSnackbar('Error al contactar al servicio de pagos', {
          preventDuplicate: true,
          variant: 'error',
        })
        setLoading(false)
        return
      }
      const valid = validateCard({ cn, ced, cvv })
      if (!valid) {
        setLoading(false)
        return
      }
    }

    const {
      generalData: {
        brandFiles,
        brandType,
        brandName,
        searchProductsAndServices,
        applicantKind,
        applicantName,
        applicantSurname,
        applicantSecondSurname,
      },
    } = brand

    const uploadedFiles =
      filesUploaded ||
      (await uploadFilesToS3(brandFiles, authenticated, currentUser))
    setFilesUploaded(uploadedFiles)

    const { email, phoneNumber, phoneNumberDialCode } = data
    const preApplicationCreated = preApplicationID
      ? { id: preApplicationID }
      : await postTrademarkPreApplication({
          name: brandName,
          email,
          typeID: brandType,
          files: uploadedFiles?.length > 0 ? uploadedFiles : undefined,
          applicantName,
          applicantSurname,
          applicantSecondSurname,
          applicantKind,
          countryCallingCode: phoneNumberDialCode,
          phoneNumber,
          trademarkApplicationStatus: trademarkApplicationStatuses.completed,
          trademarkApplicationStage: trademarkApplicationStages.registration,
          authenticated,
        })

    if (preApplicationCreated) {
      setPreApplicationID(preApplicationCreated?.id)
      const createdProductsAndServices = productsServicesCreated
        ? []
        : await createTrademarkApplicationProductsServices(
            searchProductsAndServices,
            preApplicationCreated?.id,
            email
          )
      if (createdProductsAndServices?.length > 0) {
        setProductsServicesCreated(true)
      }
    }

    if (paymentMethod === PAYMENT_METHOD_CARD) {
      // eslint-disable-next-line no-undef
      // const deviceSessionId = OpenPay?.deviceData?.setup('paymentForm', '')
      // const valid = validateCard({ cn, ced, cvv })
      // if (valid) {
      try {
        const createdCardResponse = await createOpenPayCard({
          cn,
          chn,
          ced,
          cvv,
        })
        const paymentResponse = await createCardPayment({
          id: preApplicationCreated?.id,
          deviceId: deviceSessionId,
          token: createdCardResponse.data.id,
          isAuthenticated: authenticated,
        })

        if (paymentResponse?.errorMessage) {
          setLoading(false)
          enqueueSnackbar(paymentResponse?.errorMessage, {
            preventDuplicate: true,
            variant: 'error',
          })
        }
        if (paymentResponse?.paymentTransaction) {
          const {
            trademarkApplicationId,
            paymentTransaction: { id: paymentTXID },
          } = paymentResponse
          setPaymentTransactionID(paymentTXID)
          setTrademarkAppID(trademarkApplicationId)
          if (authenticated) {
            const trademarkAppReaded = await readTrademarkApplication({
              id: trademarkApplicationId,
              authenticated,
            })
            const trademarkAppUpdated = await moveTrademarkToStage({
              trademarkApp: trademarkAppReaded,
              trademarkApplicationStage:
                trademarkApplicationStages.supplementalData,
              includeBrands: true,
              alterBrandData: true,
              authenticated,
            })
            setTrademarkApp({ ...trademarkAppUpdated })
          }

          if (paymentResponse.redirect) {
            window.location = paymentResponse.redirect
          } else {
            // Invoke at least 3 times in order to retrieve status.
            const paymentStatus = await retrievePaymentTXStatus({
              id: paymentTXID,
              maxAttempts: 3,
              delayInms: 3000,
            })

            if (paymentStatus.status === 'paid') {
              enqueueSnackbar('Pago procesado correctamente', {
                preventDuplicate: true,
                variant: 'success',
              })

              setGeneratedPayment(true)
              setFilesUploaded(null)
            } else {
              throw new Error('processing error')
            }
            setLoading(false)
          }
        }
      } catch (e) {
        enqueueSnackbar('Ocurrió un error al procesar el pago', {
          preventDuplicate: true,
          variant: 'error',
        })
        setCurrentStep((prevActiveStep) => prevActiveStep - 1)
        setLoading(false)
      }
      // }
    }
    if (
      paymentMethod === PAYMENT_METHOD_DEPOSIT ||
      paymentMethod === PAYMENT_METHOD_STORE
    ) {
      if (paymentTransactionID || trademarkAppID || generatedPayment) {
        await handleRetrieveVoucher(paymentMethod)
        return
      }

      const paymentResponse = await createStoreOrDepositPayment({
        id: preApplicationCreated?.id,
        isAuthenticated: authenticated,
        paymentMethod,
      })
      if (paymentResponse?.errorMessage) {
        if (paymentResponse?.errorMessage !== undefined) {
          setLoading(false)
          enqueueSnackbar(paymentResponse?.errorMessage, {
            preventDuplicate: true,
            variant: 'error',
          })
        }
      }
      if (paymentResponse?.documentUrl) {
        const {
          trademarkApplicationId,
          paymentTransaction: { id: paymentTXID },
        } = paymentResponse
        setPaymentTransactionID(paymentTXID)
        setTrademarkAppID(trademarkApplicationId)
        if (authenticated) {
          const trademarkAppReaded = await readTrademarkApplication({
            id: trademarkApplicationId,
            authenticated,
          })
          const trademarkAppUpdated = await moveTrademarkToStage({
            trademarkApp: trademarkAppReaded,
            trademarkApplicationStage:
              trademarkApplicationStages.supplementalData,
            includeBrands: true,
            alterBrandData: true,
            authenticated,
          })
          setTrademarkApp({ ...trademarkAppUpdated })
        }
        setReferenceUrl(paymentResponse?.documentUrl)
        setGeneratedPayment(true)
        setFilesUploaded(null)
      }
    }
  }

  const handleClickEnableEmail = () => {
    setEnabledEmail((prevEnabledEmail) => !prevEnabledEmail)
    // https://stackoverflow.com/a/41959449
    emailRef.current.disabled = false
    // https://stackoverflow.com/questions/37949394/how-to-set-focus-to-a-materialui-textfield
    emailRef.current.focus()
  }

  const handleMouseDownEmail = (event) => {
    event.preventDefault()
  }

  const openPhoneNumberInformation = () => {
    openModal({
      title: 'Número de teléfono',
      content: (
        <div className={classes.infoText}>
          Tu número de teléfono nos servirá para mantenerte informado sobre el
          avance de tu solicitud.
        </div>
      ),
      confirmationText: 'Cerrar',
      cancellationText: null,
    })
  }

  const requestedClasses = _.chain(
    brand?.generalData?.searchProductsAndServices
  )
    .map((ps) => Number(ps.classID))
    .uniq()
    .orderBy((v) => v)
    .map((v) => {
      return {
        classID: v,
        concept: `Registro de Marca Clase ${v}`,
        rights: impiRightsFee?.amount,
        amount: trademarkRegistrationFee?.amount,
      }
    })
    .value()

  const subtotal = (items, amount) => {
    return items.map((item) => item[amount]).reduce((sum, i) => sum + i, 0)
  }

  const isMobileDevice = useMediaQuery(theme.breakpoints.down('xs'))
  const isTabletDevice = useMediaQuery(theme.breakpoints.down('sm'))

  const invoiceRightsSubtotal = subtotal(requestedClasses, 'rights')
  const invoiceFeeSubtotal = subtotal(requestedClasses, 'amount')
  const invoiceFeeTaxes = TAX_RATE * invoiceFeeSubtotal
  const invoiceFeeTotal = invoiceFeeTaxes + invoiceFeeSubtotal
  const invoiceTotal = invoiceFeeTotal + invoiceRightsSubtotal
  const IVA = `${(TAX_RATE * 100).toFixed(0)} %`

  // Este método se utiliza para retomar el flujo después de validaciones
  // como 3D Secure
  const redirectInit = async () => {
    // eslint-disable-next-line no-restricted-globals
    const q = new URLSearchParams(location.search)
    if (q.get('tx')) {
      setLoading(true)
      try {
        const tx = await retrievePaymentTXStatus({
          id: q.get('tx'),
        })
        enqueueSnackbar('Pago procesado correctamente', {
          preventDuplicate: true,
          variant: 'success',
        })
        if (tx.status === 'paid') {
          setGeneratedPayment(true)
          setFilesUploaded(null)
          setCurrentStep(
            (prevActiveStep) => prevActiveStep + (authenticated ? 2 : 1)
          )
        } else {
          enqueueSnackbar('Ocurrió un error al procesar el pago', {
            preventDuplicate: true,
            variant: 'error',
          })
          setCurrentStep((prevActiveStep) => prevActiveStep - 1)
        }
      } catch (e) {
        enqueueSnackbar('Ocurrió un error al procesar el pago', {
          preventDuplicate: true,
          variant: 'error',
        })
        setCurrentStep((prevActiveStep) => prevActiveStep - 1)
      } finally {
        setLoading(false)
      }
    }
  }

  useEffect(() => {
    redirectInit()
  }, [])

  const renderPurchaseSummary = () => {
    return (
      <>
        <Hidden smDown>
          <Grid item md={2} />
        </Hidden>
        <Grid item xs={12} md={8}>
          <Paper className={classes.paper} variant="outlined">
            <Typography
              className={classes.tableTitle}
              variant="h6"
              id="tableTitle"
              component="div">
              Resumen de la compra
            </Typography>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell
                      align="center"
                      colSpan={isMobileDevice ? 2 : 4}
                      className={clsx(classes.tableHeaders, classes.noBorder)}>
                      Concepto
                    </TableCell>
                    <TableCell
                      align="center"
                      colSpan={isMobileDevice ? 2 : 2}
                      className={clsx(classes.tableHeaders, classes.noBorder)}>
                      {isMobileDevice ? 'Derechos' : 'Derechos del IMPI'}
                    </TableCell>
                    <TableCell
                      align="center"
                      colSpan={isMobileDevice ? 2 : 2}
                      className={clsx(classes.tableHeaders, classes.noBorder)}>
                      Honorarios
                    </TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {requestedClasses.map((row, i) => (
                    <TableRow hover key={row.classID}>
                      <TableCell
                        colSpan={isMobileDevice ? 2 : 4}
                        className={clsx(
                          classes.tableText,
                          i < requestedClasses.length - 1 && classes.noBorder
                        )}>
                        {row.concept}
                      </TableCell>
                      <TableCell
                        align="right"
                        colSpan={isMobileDevice ? 2 : 2}
                        className={clsx(
                          classes.tableText,
                          i < requestedClasses.length - 1 && classes.noBorder
                        )}>
                        <>{currencyFormat(row.rights, '$  ', 2)} MXN</>
                      </TableCell>
                      <TableCell
                        align="right"
                        colSpan={isMobileDevice ? 2 : 2}
                        className={clsx(
                          classes.tableText,
                          i < requestedClasses.length - 1 && classes.noBorder
                        )}>
                        <>{currencyFormat(row.amount, '$  ', 2)} MXN</>
                      </TableCell>
                    </TableRow>
                  ))}

                  <TableRow hover>
                    <TableCell
                      colSpan={isMobileDevice ? 2 : 4}
                      className={clsx(
                        classes.emphasizedText,
                        classes.noBorder
                      )}>
                      Subtotal
                    </TableCell>
                    <TableCell
                      align="right"
                      colSpan={isMobileDevice ? 2 : 2}
                      className={clsx(classes.tableText, classes.noBorder)}>
                      {currencyFormat(invoiceRightsSubtotal, '$  ', 2)} MXN
                    </TableCell>
                    <TableCell
                      align="right"
                      colSpan={isMobileDevice ? 2 : 2}
                      className={clsx(classes.tableText, classes.noBorder)}>
                      {currencyFormat(invoiceFeeSubtotal, '$  ', 2)} MXN
                    </TableCell>
                  </TableRow>
                  <TableRow hover>
                    <TableCell
                      colSpan={isMobileDevice ? 2 : 4}
                      className={clsx(classes.emphasizedText, classes.noBorder)}
                    />
                    <TableCell
                      colSpan={isMobileDevice ? 2 : 2}
                      className={clsx(
                        classes.emphasizedText,
                        classes.noBorder
                      )}>
                      IVA {IVA}
                    </TableCell>
                    <TableCell
                      align="right"
                      colSpan={isMobileDevice ? 2 : 2}
                      className={clsx(classes.tableText, classes.noBorder)}>
                      {currencyFormat(invoiceFeeTaxes, '$  ', 2)} MXN
                    </TableCell>
                  </TableRow>
                  <TableRow hover>
                    <TableCell
                      colSpan={isMobileDevice ? 2 : 4}
                      className={clsx(classes.emphasizedText, classes.noBorder)}
                    />
                    <TableCell
                      colSpan={isMobileDevice ? 2 : 2}
                      className={clsx(
                        classes.emphasizedText,
                        classes.noBorder
                      )}>
                      Total honorarios
                    </TableCell>
                    <TableCell
                      align="right"
                      colSpan={isMobileDevice ? 2 : 2}
                      className={clsx(classes.tableText, classes.noBorder)}>
                      {currencyFormat(invoiceFeeTotal, '$  ', 2)} MXN
                    </TableCell>
                  </TableRow>
                  <TableRow hover>
                    <TableCell
                      colSpan={isMobileDevice ? 2 : 4}
                      className={clsx(classes.emphasizedText, classes.noBorder)}
                    />
                    <TableCell
                      colSpan={isMobileDevice ? 2 : 2}
                      className={clsx(
                        classes.emphasizedText,
                        classes.noBorder
                      )}>
                      Total derechos
                    </TableCell>
                    <TableCell
                      align="right"
                      colSpan={isMobileDevice ? 2 : 2}
                      className={clsx(classes.tableText, classes.noBorder)}>
                      {currencyFormat(invoiceRightsSubtotal, '$  ', 2)} MXN
                    </TableCell>
                  </TableRow>
                  <TableRow hover>
                    <TableCell
                      colSpan={isMobileDevice ? 2 : 4}
                      className={clsx(
                        classes.emphasizedText,
                        classes.invoiceTotal,
                        classes.noBorder
                      )}
                    />
                    <TableCell
                      colSpan={isMobileDevice ? 2 : 2}
                      className={clsx(
                        classes.emphasizedText,
                        classes.invoiceTotal,
                        classes.noBorder
                      )}>
                      Total a pagar
                    </TableCell>
                    <TableCell
                      align="right"
                      colSpan={isMobileDevice ? 2 : 2}
                      className={clsx(
                        classes.emphasizedText,
                        classes.invoiceTotal,
                        classes.noBorder
                      )}>
                      {currencyFormat(invoiceTotal, '$  ', 2)} MXN
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </Grid>
        <Hidden smDown>
          <Grid item md={2} />
        </Hidden>
      </>
    )
  }

  const disabledOptions =
    referenceUrl ||
    filesUploaded ||
    preApplicationID ||
    productsServicesCreated ||
    paymentTransactionID ||
    trademarkAppID ||
    generatedPayment

  const newDisabledOptions =
    referenceUrl || paymentTransactionID || trademarkAppID || generatedPayment

  const disabledPaymentCardOptions = generatedPayment

  const renderContactInformation = () => {
    return (
      <>
        <Grid item xs={12} sm={6}>
          <FormField
            id="email"
            name="email"
            label="Correo electrónico"
            type="email"
            required
            disabled={!enabledEmail || disabledOptions || authenticated}
            variant="outlined"
            size="small"
            fullWidth
            className={clsx(classes.margin, classes.noLabel)}
            inputRef={(e) => {
              emailRef.current = e
              // https://reactjs.org/docs/refs-and-the-dom.html
              // https://github.com/react-hook-form/react-hook-form/issues/230
              register(e, {
                required: {
                  value: true,
                  message: 'Ingresa tu correo electrónico',
                },
                minLength: {
                  value: 6,
                  message: 'Debe ser mayor o igual a 6 caracteres',
                },
                pattern: {
                  value: isEmail,
                  message: 'Debes ingresar un correo electrónico válido',
                },
              })
            }}
            errors={errors}
            onChange={async () => {
              if (errors?.email) await trigger('email')
            }}
            onBlur={async () => {
              if (await trigger('email')) setEnabledEmail(false)
            }}
            InputProps={{
              endAdornment:
                !enabledEmail && !disabledOptions && !authenticated ? (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickEnableEmail}
                      onMouseDown={handleMouseDownEmail}>
                      <CreateOutlinedIcon />
                    </IconButton>
                  </InputAdornment>
                ) : null,
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormField
            id="emailConfirmation"
            name="emailConfirmation"
            label="Confirmar correo electrónico"
            type="email"
            required
            disabled={disabledOptions || authenticated}
            variant="outlined"
            size="small"
            fullWidth
            className={clsx(classes.margin, classes.noLabel)}
            inputRef={register({
              required: {
                value: true,
                message: 'Confirma tu correo electrónico',
              },
              minLength: {
                value: 6,
                message: 'Debe ser mayor o igual a 6 caracteres',
              },
              pattern: {
                value: isEmail,
                message: 'Debes ingresar un correo electrónico válido',
              },
              validate: {
                matchesPreviousEmail: (value) => {
                  const { email } = getValues()
                  return email === value || 'Los correos no coinciden'
                },
              },
            })}
            errors={errors}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <PhoneField
            id="phoneNumber"
            name="phoneNumber"
            label="Teléfono"
            dialCodeLabel="Código"
            key="phoneNumber"
            required={false}
            disabled={disabledOptions}
            variant="outlined"
            size="small"
            fullWidth
            renderFlag
            renderDialCodeLabel
            className={clsx(classes.margin, classes.noLabel)}
            control={control}
            rules={{
              required: {
                value: false,
                message: 'Ingresa tu número de teléfono',
              },
            }}
            rulesForDialCode={{
              required: {
                value: false,
                message: 'Selecciona el código de tu país',
              },
            }}
            errors={errors}
            inputProps={{
              // eslint-disable-next-line no-nested-ternary
              format: '## #### ####',
              mask: ' ',
            }}
            // eslint-disable-next-line react/jsx-no-duplicate-props
            InputProps={{
              inputComponent: NumberFormatCustom,
            }}
          />
          <FormHelperText
            classes={{
              root: classes.formHelperText,
            }}>
            <Link onClick={openPhoneNumberInformation}>
              ¿Por qué lo requerimos?
            </Link>
          </FormHelperText>
        </Grid>
      </>
    )
  }

  const handleInputCardFieldsFocused = ({ name }) => {
    setCardFocused(name)
  }

  // eslint-disable-next-line no-unused-vars
  const handleCardCallback = ({ issuer, maxLength }, isValid) => {
    setValidCard(isValid)
  }

  const icons = {
    width: '30px',
    height: '25px',
  }

  const openpayLogo = () => {
    return (
      <Link href="https://www.openpay.mx/" rel="noreferrer" target="_blank">
        <img
          alt="openpay"
          src="openpay_color.png"
          className={classes.openpayLogo}
        />
      </Link>
    )
  }

  const renderButtons = () => {
    const renderBackAndCancelButton =
      !referenceUrl &&
      /* !filesUploaded &&
      !preApplicationID &&
      !productsServicesCreated && */
      !paymentTransactionID &&
      !trademarkAppID &&
      !generatedPayment
    const renderPaymentButton =
      paymentMethod === PAYMENT_METHOD_CARD ? !generatedPayment : !referenceUrl

    const referenceButtonText = generatedPayment ? 'Recuperar' : 'Generar'
    const paymentButtonText =
      paymentMethod === PAYMENT_METHOD_CARD ? 'Pagar' : referenceButtonText
    return (
      <div className={classes.nextButtons}>
        <Grid
          container
          direction="row"
          justify="center"
          alignItems="flex-end"
          className={classes.nextButtonContainer}>
          {renderBackAndCancelButton && (
            <Grid
              item
              xs={10}
              sm={4}
              md={3}
              lg={2}
              className={classes.nextButton}>
              <Button
                onClick={() => {
                  setLoading(true)
                  setTimeout(() => {
                    setFilesUploaded(null)
                    setPreApplicationID(null)
                    setProductsServicesCreated(false)
                    setBrand({
                      generalData: {
                        ...brand?.generalData,
                        ...currentFieldValues,
                      },
                    })
                    setCurrentStep((prevActiveStep) => prevActiveStep - 1)
                    setLoading(false)
                  }, 200)
                }}
                variant="contained"
                color="secondary"
                fullWidth>
                Regresar
              </Button>
            </Grid>
          )}
          {renderBackAndCancelButton && (
            <Grid
              item
              xs={10}
              sm={4}
              md={3}
              lg={2}
              className={classes.nextButton}>
              <Button
                onClick={() => {
                  openCancelTradeMarkRegistrationModal()
                }}
                variant="contained"
                color="secondary"
                fullWidth>
                Cancelar
              </Button>
            </Grid>
          )}
          {renderPaymentButton && (
            <Grid
              item
              xs={10}
              sm={4}
              md={3}
              lg={2}
              className={classes.nextButton}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                fullWidth>
                {paymentButtonText}
              </Button>
            </Grid>
          )}
        </Grid>
      </div>
    )
  }

  const renderNextButtons = () => {
    return (
      <div className={classes.nextButtons}>
        <Grid
          container
          direction="row"
          justify="center"
          alignItems="flex-end"
          className={classes.nextButtonContainer}>
          <Grid
            item
            xs={10}
            sm={4}
            md={3}
            lg={2}
            className={classes.nextButton}>
            <Button
              onClick={() => {
                setCurrentStep(
                  (prevActiveStep) => prevActiveStep + (authenticated ? 2 : 1)
                )
              }}
              variant="contained"
              color="primary"
              fullWidth>
              Siguiente
            </Button>
          </Grid>
        </Grid>
      </div>
    )
  }

  const renderPaymentMethodCard = () => {
    return (
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} className={classes.card}>
            <Grid container spacing={2}>
              <Hidden xsDown>
                <Grid item sm={12} />
              </Hidden>
              <Grid item xs={12} sm={12}>
                <Card
                  number={cardNumber || ''}
                  name={cardholderName || ''}
                  expiry={cardExpirationDate || ''}
                  cvc={cardCVCCode || ''}
                  acceptedCards={acceptedCards}
                  placeholders={{
                    name: 'Titular',
                  }}
                  locale={{
                    valid: 'Vence hasta',
                  }}
                  focused={cardFocused}
                  callback={handleCardCallback}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Grid container spacing={2}>
              <Hidden smDown>
                <Grid item md={3} />
              </Hidden>
              <Grid item xs={4} md={2}>
                <CardVisaIcon style={icons} />
              </Grid>
              <Grid item xs={4} md={2}>
                <CardMasterIcon style={icons} />
              </Grid>
              <Grid item xs={4} md={2}>
                <CardAmexIcon style={icons} />
              </Grid>
              <Hidden smDown>
                <Grid item md={3} />
              </Hidden>
            </Grid>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={12}>
                <FormField
                  id="cardNumber"
                  name="cardNumber"
                  label="Número de tarjeta"
                  type="tel"
                  required
                  disabled={disabledPaymentCardOptions}
                  variant="outlined"
                  size="small"
                  fullWidth
                  autoComplete="cc-number"
                  className={clsx(classes.margin, classes.noLabel)}
                  control={control}
                  rules={{
                    required: {
                      value: true,
                      message: 'Confirma tu tarjeta',
                    },
                    minLength: {
                      value: 13,
                      message: 'Debe ser mayor o igual a 13 caracteres',
                    },
                    maxLength: {
                      value: 19,
                      message: 'Debe ser menor o igual a 19 caracteres',
                    },
                  }}
                  errors={errors}
                  inputProps={{
                    // eslint-disable-next-line no-nested-ternary
                    format: cardIssuer
                      ? cardIssuer === CARD_ISSUER_AMEX
                        ? '#### ###### #####'
                        : '#### #### #### #######'
                      : '#### #### #### ####',
                    // mask: '•',
                  }}
                  onFocus={() =>
                    handleInputCardFieldsFocused({ name: 'number' })
                  }
                  onBlur={() => handleInputCardFieldsFocused({ name: null })}
                  onChange={(e) => {
                    const issuer = CardPayment.fns.cardType(e?.target?.value)
                    setCardIssuer(issuer)
                  }}
                  // eslint-disable-next-line react/jsx-no-duplicate-props
                  InputProps={{
                    inputComponent: NumberFormatCustom,
                    endAdornment: (
                      <InputAdornment position="end">
                        {cardIssuer ? (
                          <img
                            alt="cardIssuer"
                            src={`${cardIssuer}.png`}
                            className={classes.cardIssuer}
                          />
                        ) : (
                          <CreditCardOutlinedIcon />
                        )}
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <FormField
                  id="cardholderName"
                  name="cardholderName"
                  label="Titular"
                  type="text"
                  required
                  disabled={disabledPaymentCardOptions}
                  variant="outlined"
                  size="small"
                  fullWidth
                  autoComplete="cc-number"
                  className={clsx(classes.margin, classes.noLabel)}
                  inputRef={register({
                    required: {
                      value: true,
                      message: 'Confirma el titular de tu tarjeta',
                    },
                    minLength: {
                      value: 2,
                      message: 'Debe ser mayor o igual a 2 caracteres',
                    },
                    maxLength: {
                      value: 60,
                      message: 'Debe ser menor o igual a 60 caracteres',
                    },
                  })}
                  errors={errors}
                  inputProps={{
                    minLength: 2,
                    maxLength: 60,
                  }}
                  onFocus={() => handleInputCardFieldsFocused({ name: 'name' })}
                  onBlur={() => handleInputCardFieldsFocused({ name: null })}
                />
              </Grid>
              <Grid item xs={6} sm={6}>
                <FormField
                  id="cardExpirationDate"
                  name="cardExpirationDate"
                  label="Vence hasta"
                  type="text"
                  required
                  disabled={disabledPaymentCardOptions}
                  variant="outlined"
                  size="small"
                  fullWidth
                  autoComplete="cc-number"
                  className={clsx(classes.margin, classes.noLabel)}
                  control={control}
                  rules={{
                    required: {
                      value: true,
                      message: 'Confirma la fecha de expiración de tu tarjeta',
                    },
                    minLength: {
                      value: 4,
                      message: 'Debe ser mayor o igual a 4 caracteres',
                    },
                    maxLength: {
                      value: 4,
                      message: 'Debe ser menor o igual a 4 caracteres',
                    },
                  }}
                  errors={errors}
                  inputProps={{
                    format: cardExpiryFormat,
                    placeholder: 'MM/YY',
                  }}
                  onFocus={() =>
                    handleInputCardFieldsFocused({ name: 'expiry' })
                  }
                  onBlur={() => handleInputCardFieldsFocused({ name: null })}
                  // eslint-disable-next-line react/jsx-no-duplicate-props
                  InputProps={{
                    inputComponent: NumberFormatCustom,
                  }}
                />
              </Grid>
              <Grid item xs={6} sm={6}>
                <FormField
                  id="cardCVCCode"
                  name="cardCVCCode"
                  label="CVV | CVC"
                  type={disabledPaymentCardOptions ? 'password' : 'text'}
                  required
                  disabled={disabledPaymentCardOptions}
                  variant="outlined"
                  size="small"
                  fullWidth
                  autoComplete="cc-number"
                  className={clsx(classes.margin, classes.noLabel)}
                  control={control}
                  rules={{
                    required: {
                      value: true,
                      message:
                        'Confirma el código de verificación de tu tarjeta',
                    },
                    minLength: {
                      value: cardIssuer === CARD_ISSUER_AMEX ? 4 : 3,
                      message:
                        cardIssuer === CARD_ISSUER_AMEX
                          ? 'Debe ser igual a 4 caracteres'
                          : 'Debe ser igual a 3 caracteres',
                    },
                    maxLength: {
                      value: 4,
                      message: 'Debe ser menor o igual a 4 caracteres',
                    },
                  }}
                  errors={errors}
                  inputProps={{
                    // eslint-disable-next-line no-nested-ternary
                    format: cardIssuer === CARD_ISSUER_AMEX ? '####' : '###',
                    mask: '•',
                  }}
                  onFocus={() => handleInputCardFieldsFocused({ name: 'cvc' })}
                  onBlur={() => handleInputCardFieldsFocused({ name: null })}
                  // eslint-disable-next-line react/jsx-no-duplicate-props
                  InputProps={{
                    inputComponent: NumberFormatCustom,
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
          {generatedPayment && (
            <Grid item xs={12}>
              {renderNextButtons()}
            </Grid>
          )}
        </Grid>
      </Grid>
    )
  }

  const renderPaymentMethodDeposit = ({ referenceType }) => {
    return (
      <Grid item xs={12}>
        {generatedPayment && renderNextButtons()}
        {referenceUrl && (
          <Grid item xs={12} sm={12}>
            <Grid container spacing={2}>
              <Hidden xsDown>
                <Grid item sm={1} md={2} lg={3} />
              </Hidden>
              <Grid
                item
                xs={12}
                sm={10}
                md={8}
                lg={6}
                className={clsx(classes.referencePDFContainer)}>
                <iframe
                  key={`${referenceType}-${referenceKey}`}
                  title="pdf"
                  src={referenceUrl}
                  type="application/pdf"
                  onLoad={() => completeBankOrStorePayment()}
                />
              </Grid>
              <Hidden xsDown>
                <Grid item sm={1} md={2} lg={3} />
              </Hidden>
            </Grid>
          </Grid>
        )}
      </Grid>
    )
  }

  renderPaymentMethodDeposit.propTypes = {
    referenceType: PropTypes.oneOf([
      PAYMENT_METHOD_DEPOSIT,
      PAYMENT_METHOD_STORE,
    ]).isRequired,
  }

  return (
    <form id="paymentForm" noValidate onSubmit={handleSubmit(onSubmit)}>
      <div className={classes.root}>
        <Container>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Title>Pago</Title>
            </Grid>
            {trademarkRegistrationFee !== null &&
              impiRightsFee !== null &&
              renderPurchaseSummary()}
            {renderContactInformation()}
            <Grid item xs={12} className={clsx(classes.paymentMethod)}>
              <Grid item xs={12}>
                Método de pago
              </Grid>
              <Grid item xs={12} className={clsx(classes.paymentMethods)}>
                <FormFieldMultipleOptions
                  id="paymentMethod"
                  name="paymentMethod"
                  label="Selecciona tu método de pago"
                  type="radio"
                  required
                  variant="outlined"
                  size="small"
                  fullWidth
                  disabled={newDisabledOptions}
                  row={!isMobileDevice && !isTabletDevice}
                  className={clsx(classes.margin, classes.noLabel)}
                  options={paymentMethodsOptions}
                  errors={errors}
                  control={control}
                  rules={{
                    required: {
                      value: true,
                      message: 'Selecciona un método de pago',
                    },
                  }}
                  value={paymentMethod}
                />
              </Grid>
            </Grid>
            {paymentMethod === PAYMENT_METHOD_CARD && renderPaymentMethodCard()}
            {paymentMethod === PAYMENT_METHOD_DEPOSIT &&
              renderPaymentMethodDeposit({
                referenceType: PAYMENT_METHOD_DEPOSIT,
              })}
            {paymentMethod === PAYMENT_METHOD_STORE &&
              renderPaymentMethodDeposit({
                referenceType: PAYMENT_METHOD_STORE,
              })}
            <Grid item xs={12} sm={12}>
              {renderButtons()}
            </Grid>
            {!generatedPayment && (
              <Grid item xs={12} className={classes.openpayLogoContainer}>
                {openpayLogo()}
              </Grid>
            )}
          </Grid>
        </Container>
      </div>
    </form>
  )
}

export default Payment
